import { Alert, Box, Button, Modal, Multiselect, SpaceBetween, Spinner, StatusIndicator, TextContent } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { addRabbitBrokerTag, deleteRabbitBrokerTag, getRabbitBrokerTags } from '../../api/api';
import CopyToClipboardButton from './copyToClipboardButton';

type Props = {
    brokerId: string,
}

const AVAILABLE_TAGS = [
    'INSUFFICIENT_INSTANCE_SIZE',
    'QUORUM_QUEUES_EXIST',
    'STREAM_QUEUES_EXIST',
    'QUEUE_SYNC_OOM',
    'STOP_ALL_MW',
    'EXCLUDE_FROM_STUCK_BROKERS',
    'VHOST_UNRESPONSIVE'
]

const BrokerTagsComponent : React.FC<Props> = ({brokerId}) => {

    const [tags, setTags] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [deletingTag, setDeletingTag] = useState<string>("");
    const [confirmationDialogVisible, setConfirmationDialogVisible] = useState<boolean>(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState<string>("");
    const [confirmationDialogAction, setConfirmationDialogAction] = useState<() => void>(() => {});

    useEffect(() => {
        setError("");
        setLoading(true);
        getRabbitBrokerTags(brokerId).then(response => {
            setTags(response.brokerTags.brokerTags);
        }).catch(error => {
            console.error(error);
            setError("Could not load broker tags");
        }).finally(() => {
            setLoading(false);
        })
    }, [brokerId]);

    function onTagAdded(newTag: string) {
        setLoading(true);
        setError("");
        addRabbitBrokerTag(brokerId, newTag).then(response => {
            if (!tags.includes(newTag)) {
                setTags([...tags, newTag]);
            }
        }).catch(error => {
            console.error(error);
            if (error.message) {
                setError(error.message);
            } else {
                setError("Could not add tag");
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    function onTagDeleted(deletedTag: string) {
        setLoading(true);
        setError("");
        setDeletingTag(deletedTag);
        deleteRabbitBrokerTag(brokerId, deletedTag).then(response => {
            let idx = tags.findIndex(t => t === deletedTag);
            if (idx >= 0) {
                let newTags = [...tags];
                newTags.splice(idx, 1);
                setTags(newTags);
            }
        }).catch(error => {
            console.error(error);
            if (error.message) {
                setError(error.message);
            } else {
                setError("Could not delete tag");
            }
        }).finally(() => {
            setLoading(false);
            setDeletingTag("");
        })
    }

    function handleChange(newTags: string[]) {
        if (newTags.length > tags.length) {
            let newTag = newTags.find(t => tags.indexOf(t) < 0);
            if (newTag !== undefined) {
                setConfirmationDialogVisible(true);
                setConfirmationDialogText(`Add tag ${newTag}?`);
                setConfirmationDialogAction(() => () => {
                    onTagAdded(newTag!);
                })
            }
        } else if (newTags.length < tags.length) {
            setConfirmationDialogVisible(true);
            let deletedTag = tags.find(t => newTags.indexOf(t) < 0);
            if (deletedTag !== undefined) {
                setConfirmationDialogVisible(true);
                setConfirmationDialogText(`Delete tag ${deletedTag}?`);
                setConfirmationDialogAction(() => () => {
                    onTagDeleted(deletedTag!);
                })
            }
        }
    }

    let copyText = tags.join(", ");

    return (
      <>
          <Modal
            onDismiss={() => setConfirmationDialogVisible(false)}
            visible={confirmationDialogVisible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => {
                            setConfirmationDialogVisible(false)
                        }}>Cancel</Button>
                        <Button variant="primary" onClick={() => {
                            confirmationDialogAction();
                            setConfirmationDialogVisible(false);
                        }}>Ok</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Confirmation"
          >
              {confirmationDialogText}
          </Modal>
          <div key="Broker Tags">
              <div className="awsui-util-label">
                  <strong><span>Broker Tags</span></strong>
                  {copyText && <CopyToClipboardButton text={copyText}/>}
              </div>
              <div>
                  <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                      <div style={{display: 'flex', flexDirection: 'row', gap: 8}}>
                          <ul>
                              {tags.map((item, index) => (
                                <li key={index}>{item}</li> // Make sure to use a unique key for each list item
                              ))}
                          </ul>
                          {/*<Multiselect*/}
                          {/*  selectedOptions={tags.map(t => {*/}
                          {/*      return {label: t, value: t, disabled: t === deletingTag}*/}
                          {/*  })}*/}
                          {/*  empty="No tags"*/}
                          {/*  keepOpen={false}*/}
                          {/*  onChange={({detail}) => {*/}
                          {/*      handleChange(detail.selectedOptions.map(option => {*/}
                          {/*          return option.value!*/}
                          {/*      }));*/}
                          {/*  }}*/}
                          {/*  options={AVAILABLE_TAGS.map(t => {*/}
                          {/*      return {*/}
                          {/*          label: t,*/}
                          {/*          value: t,*/}
                          {/*      }*/}
                          {/*  })}*/}
                          {/*  placeholder="Edit tags"*/}
                          {/*/>*/}
                      </div>
                      {loading && <Spinner/>}
                      {!loading && tags.length === 0 && <TextContent>None</TextContent>}
                      {error && <StatusIndicator type='error'>{error}</StatusIndicator>}
                      <Alert type="warning">
                          Editing feature is temporarily removed from UI. AMQ operators please use CLI tool for broker tag management.
                      </Alert>
                  </div>
              </div>
          </div>
      </>
    );
}

export default BrokerTagsComponent;
