import * as Arn from "@aws-sdk/util-arn-parser";
import { widget } from "../utils";
import { Broker } from "@amzn/amazonmq-opsconsole-client";

export function getActiveBrokerMetrics(broker: Broker): any[] {
    let brokerMetrics: any[] = [];
    let region = Arn.parse(broker.brokerInfo.arn).region;

    brokerMetrics.push(widget(
        "Data Partition Used",
        region,
        [
            dataPartitionUsedMetric(broker),
            txTmpPartitionUsageMetric(broker),
            rootPartitionUtilizationMetric(broker)
        ]
    ))

    brokerMetrics.push(widget(
        "CPU Utilization",
        region,
        [
            cpuUtilizationMetric(broker),
        ]
    ))

    brokerMetrics.push(widget(
        "Network I/O",
        region,
        [
            networkInMetric(broker),
            networkOutMetric(broker),
        ]
    ))

    brokerMetrics.push(widget(
        "Total Message Count",
        region,
        [
            totalMessageCountMetric(broker),
            messageSizeMetric(broker),
        ]
    ))

    brokerMetrics.push(widget(
        "Consumers / Producers",
        region,
        [
            totalConsumerCountMetric(broker),
            totalProducerCountMetric(broker),
        ]
    ))

    brokerMetrics.push(widget(
        "Queues / Topics",
        region,
        [
            totalQueueCountMetric(broker),
            totalTopicCountMetric(broker),
        ]
    ))

    brokerMetrics.push(widget(
        "Transactions",
        region,
        [
            journalFilesForFullRecoveryMetric(broker),
            journalFilesForFastRecoveryMetric(broker),
            openTransactionsCountMetric(broker)
        ]
    ))

    brokerMetrics.push(widget(
        "Broker Heap Memory",
        region,
        [
            brokerHeapMemoryMaxMetric(broker),
            brokerHeapMemoryCommittedMetric(broker),
            brokerHeapMemoryUsedMetric(broker),
            brokerHeapMemoryUsedAfterGCMetric(broker)
        ]
    ))

    brokerMetrics.push(widget(
        "Broker Non-Heap Memory",
        region,
        [
            brokerNonHeapMemoryCommittedMetric(broker),
            brokerNonHeapMemoryUsedMetric(broker)
        ]
    ))

    brokerMetrics.push(widget(
        "Threads & Connections",
        region,
        [
            currentConnectionsCountMetric(broker),
            establishedConnectionsCountMetric(broker),
            brokerThreadCountMetric(broker),
            agentThreadCountMetric(broker)
        ]
    ))

    brokerMetrics.push(widget(
        "Agent Heap Memory",
        region,
        [
            agentHeapMemoryMaxMetric(broker),
            agentHeapMemoryCommittedMetric(broker),
            agentHeapMemoryUsedMetric(broker)
        ]
    ))

    brokerMetrics.push(widget(
        "Agent Non-Heap Memory",
        region,
        [
            agentNonHeapMemoryCommittedMetric(broker),
            agentNonHeapMemoryUsedMetric(broker)
        ]
    ))

    brokerMetrics.push(widget(
        "Root Partition",
        region,
        [
            rootPartitionLatencyMetric(broker),
            rootPartitionAvailabilityMetric(broker)
        ]
    ))

    brokerMetrics.push(widget(
        "CRDR Role",
        region,
        [
            isCrdrPrimaryMetric(broker),
            isCrdrBrokerProcessPrimaryMetric(broker),
        ]
    ))


    return brokerMetrics;
}

export function dataPartitionUsedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "DataPartitionUsed",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function txTmpPartitionUsageMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "TxTmpPartitionUsage",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function rootPartitionUtilizationMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "RootPartitionUtilization",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function cpuUtilizationMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "CpuUtilization",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function networkInMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "NetworkIn",
        "BrokerId",
        broker.id,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function networkOutMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "NetworkOut",
        "BrokerId",
        broker.id,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function totalMessageCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "TotalMessageCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function messageSizeMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "MessageSize",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function totalConsumerCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "TotalConsumerCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function totalProducerCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "TotalProducerCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function totalQueueCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "TotalQueueCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function totalTopicCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "TotalTopicCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function journalFilesForFullRecoveryMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "JournalFilesForFullRecovery",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function journalFilesForFastRecoveryMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "JournalFilesForFastRecovery",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function openTransactionsCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "OpenTransactionCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function brokerHeapMemoryMaxMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "BrokerHeapMemoryMax",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function brokerHeapMemoryCommittedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "BrokerHeapMemoryCommitted",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function brokerHeapMemoryUsedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "BrokerHeapMemoryUsed",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function brokerHeapMemoryUsedAfterGCMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "BrokerHeapMemoryUsedAfterGC",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function brokerNonHeapMemoryCommittedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "BrokerNonHeapMemoryCommitted",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function brokerNonHeapMemoryUsedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "BrokerNonHeapMemoryUsed",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function currentConnectionsCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "CurrentConnectionsCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function establishedConnectionsCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "EstablishedConnectionsCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function brokerThreadCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "BrokerThreadCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function agentThreadCountMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "AgentThreadCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}



export function agentHeapMemoryMaxMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "AgentThreadCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function agentHeapMemoryCommittedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "AgentHeapMemoryCommitted",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function agentHeapMemoryUsedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "AgentHeapMemoryUsed",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function agentNonHeapMemoryCommittedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "AgentNonHeapMemoryCommitted",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function agentNonHeapMemoryUsedMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "AgentNonHeapMemoryUsed",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function rootPartitionLatencyMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "RootPartitionLatency",
        "BrokerId",
        broker.id,
        {
            stat: "Minimum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function rootPartitionAvailabilityMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "RootPartitionAvailability",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function isCrdrPrimaryMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "IsCrdrPrimary",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function isCrdrBrokerProcessPrimaryMetric(broker: Broker) {
    return [
        "AmazonMqService",
        "IsCrdrBrokerProcessPrimary",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}


